import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePrivate } from 'hooks';
import { NoMatch } from 'pages';
import { routes } from 'statics';
import MagixService from 'wrappers/MagixService';

const Services: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { data: profile, isLoading } = usePrivate({
    redirect: (location, navigate) => {
      if (searchParams.get('transaction_id') && searchParams.get('email')) {
        navigate(
          `${routes.auth.signIn.url}?return_url=${location.pathname + location.search}`
        );
      } else {
        navigate(routes.auth.signIn.url);
      }
    },
  });

  if (isLoading || !profile) {
    return <NoMatch />;
  }

  if (profile) {
    return <MagixService />;
  }
};
export default Services;
