/* eslint-disable no-unused-vars */
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAccess,
  useCancelServiceWithdraw,
  useSendCodeServiceTransaction,
  useTimer,
  useVerifyServiceWithdraw,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, Input } from 'components';
import { yup } from 'utils';

type PayloadType = { code: string };
type FormProps = {
  onAction: ({
    status,
    redirectUrl,
  }: {
    status: 'success' | 'cancel';
    redirectUrl: string;
  }) => void;
  transactionId: string;
};

const Form: React.FC<FormProps> = ({ onAction, transactionId }: FormProps) => {
  const { t } = useTranslation('component.services.xm.deposit.form');
  const access = useAccess();

  const { counter, setCounter } = useTimer(0);

  const { mutateAsync: submit, isLoading: submitLoading } =
    useVerifyServiceWithdraw();
  const { mutateAsync: cancel, isLoading: cancelLoading } =
    useCancelServiceWithdraw();

  const { mutateAsync: send } = useSendCodeServiceTransaction();

  const schema = yup.object().shape({
    code: yup.string().length(6).label('code').required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  const formProps = useInputAttributes(register, errors, {
    code: t('entity.code'),
  });

  const sendHandler = () => {
    send({}).then(() => {
      setCounter(120);
    });
  };

  const onSubmit = async (data: PayloadType) => {
    if (access.verifyWithdrawExternalServicePermission) {
      submit({ data, id: transactionId }).then((res) => {
        onAction({ status: 'success', redirectUrl: res.redirect_url });
      });
    }
  };

  const cancelTransaction = () => {
    if (access.rejectWithdrawExternalServicePermission) {
      cancel({ id: transactionId }).then((res) => {
        onAction({ status: 'cancel', redirectUrl: res.redirect_url });
      });
    }
  };

  const disabled = counter !== 0;

  return (
    <Box pt={8}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" gap={4}>
          <Box w="100%">
            <Input
              {...formProps.code}
              textAlign="center"
              placeholder="   -   -   -   -   -   -   "
            />
          </Box>
          <Box>
            <Button
              variant="filled"
              title={disabled ? ' ( ' + counter.toString() + ' )' : t('send')}
              isDisabled={disabled}
              minW="100px"
              mt={{ base: 0, md: 8 }}
              onClick={sendHandler}
              mb={{ base: -4, md: 4 }}
            />
          </Box>
        </Box>
        <Box
          w="full"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
        >
          <Button
            w="50%"
            type="submit"
            variant="filled"
            isLoading={submitLoading}
            title={t('submit')}
            mt={8}
          />
          <Button
            w="50%"
            variant="ghost"
            title={t('cancel')}
            mt={8}
            isLoading={cancelLoading}
            onClick={() => cancelTransaction()}
          />
        </Box>
      </form>
    </Box>
  );
};
export default Form;
