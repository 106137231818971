import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import {
  useLocalStorage,
  useResendCode,
  useTimer,
  useVerification,
} from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { AuthSuggestion, DesktopAuthHeader } from 'components';

import { Form } from './components';

const Verification: React.FC = () => {
  const { t } = useTranslation('component.auth.verification');
  const { counter, setCounter } = useTimer(120);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setItem } = useLocalStorage();

  React.useEffect(() => {
    if (!searchParams.get('username') || !searchParams.get('token')) {
      navigate(routes.auth.signIn.url);
    }
  }, []);

  const { mutateAsync: submit } = useVerification(
    searchParams.get('token') || ''
  );

  const { mutateAsync: resend } = useResendCode(
    searchParams.get('token') || ''
  );

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <DesktopAuthHeader title={t('verificationToMagix')} />
      <Form
        onButton={(data) =>
          submit({
            code: data.code,
            username: searchParams.get('username') || '',
          }).then((res) => {
            if (res?.access_token) {
              setItem('magix-token', res.access_token);
              navigate(
                `${routes.dashboard.url + '?return_url=' + (searchParams.get('return_url') || '')}${
                  searchParams.get('system_identifier')
                    ? '&system_identifier=' +
                      searchParams.get('system_identifier')
                    : ''
                }`
              );
            }
          })
        }
        counter={counter}
        resendCode={() =>
          resend({ username: searchParams.get('username') }).then(() => {
            setCounter(120);
          })
        }
      />
      <AuthSuggestion include={['forget-password', 'sign-up']} />
    </Box>
  );
};

export default Verification;
