import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useGetSignInServiceEmail, useInitiateLogin } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { AuthSuggestion, DesktopAuthHeader } from 'components';

import { Form } from './components';

const Login: React.FC = () => {
  const { t } = useTranslation('component.auth.login');
  const navigate = useNavigate();
  const { mutateAsync: submit } = useInitiateLogin();
  const [searchParams] = useSearchParams();

  const { client_email, system_identifier } = useGetSignInServiceEmail();

  const SYSTEM_IDENTIFIER = system_identifier
    ? '&system_identifier=' + system_identifier.toUpperCase()
    : '';

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <DesktopAuthHeader title={t('loginToMagix')} />
      <Form
        onButton={(data) =>
          submit(data).then((res) => {
            if (res?.token) {
              navigate(
                // eslint-disable-next-line max-len
                `${routes.auth.verification.url}?token=${res.token}&username=${data.username}&return_url=${searchParams.get('return_url') || ''}${SYSTEM_IDENTIFIER}`
              );
            }
          })
        }
        defaultEmail={client_email}
      />
      <AuthSuggestion include={['forget-password', 'sign-up']} />
    </Box>
  );
};

export default Login;
